<template>
  <div v-if="menus && menus.length">
    <template v-for="item in menus">
      <el-menu-item :key="item.menuId" v-if="item.children && item.children.length === 0" :index="item.menuId" @click="menuClick(item)">
        <i class="icon-font-size" :class="item.iconCalssName" style="margin-right: 5px"> </i>
        <span slot="title">{{ item.menuName }}</span>
      </el-menu-item>
      <el-submenu :key="item.menuId" v-if="item.children && item.children.length !== 0" :index="item.menuId">
        <template slot="title">
          <i class="icon-font-size" :class="item.iconCalssName" style="margin-right: 5px"> </i>
          <span slot="title">{{ item.menuName }}</span>
        </template>
        <template v-for="child in item.children">
          <el-menu-item
            :key="child.menuId"
            :index="child.menuId"
            @click="menuClick(child)"
            v-if="child.children && child.children.length === 0"
          >
            <i class="icon-font-size" :class="child.iconCalssName" style="margin-right: 5px"> </i>
            <span slot="title">{{ child.menuName }}</span>
          </el-menu-item>
          <menu-item :key="child.menuId" v-else :menus="[child]"></menu-item>
        </template>
      </el-submenu>
    </template>
  </div>
</template>

<script>
export default {
  name: 'MenuItem',
  data() {
    return {};
  },
  props: {
    menus: {
      type: Array,
      default: () => []
    },
    collapse: {
      type: Boolean
    },
    iconViewUrl: {
      type: String,
      default: '/river-fs/file/view'
    }
  },
  created() {},
  methods: {
    open(item) {
      // this.$router.push({
      //   path: item.menuRouterUrl
      // });
    },
    menuClick(data) {
      this.$emit('menuClick', data);
    }
  }
};
</script>
<style scoped lang="scss">
.icon-img {
  width: 22px;
  height: 22px;
  margin-right: 4px;
}
.el-menu-item {
  font-size: var(--rootFontSize);
}
</style>

